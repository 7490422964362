import { authRoles } from "./auth/authRoles";

export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard/analytics",
    icon: "dashboard",
    auth: authRoles.editor,
  },
  {
    name: "Companies",
    icon: "business",
    children: [
      {
        name: "Companies List",
        iconText: "CL",
        path: "/company/companies-list",
        auth: authRoles.editor,
      },
    ],
    auth: authRoles.editor,
  },
  {
    name: "Invoices",
    icon: "library_books",
    children: [
      {
        name: "Invoices List",
        iconText: "IL",
        path: "/invoice/invoices-list",
        auth: authRoles.sd,
      },
      {
        name: "Generate Invoice",
        iconText: "GI",
        path: "/invoice/generate-invoice",
        auth: authRoles.sd,
      },
    ],
    auth: authRoles.sd,
  },
  {
    name: "News-Letter",
    icon: "email",
    children: [
      {
        name: "Create NewsLetter",
        iconText: "CNL",
        path: "/news-letter/create-news-letter",
        auth: authRoles.editor,
      },
    ],
    auth: authRoles.editor,
  },
  {
    name: "Map",
    icon: "add_location",
    path: "/map",
    auth: authRoles.editor,
  },
];
