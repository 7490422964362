import axios from "axios";
import localStorageService from "./localStorageService";
import { config } from "../../config/config";
import { extractData, serializeQueryParams } from "../../utils";
class JwtAuthService {
  constructor() {
    this.baseUrl = "";
  }

  setBaseUrl(url) {
    this.baseUrl = url;
    localStorage.setItem("base_url", url);
  }

  getBaseUrl() {
    return localStorage.getItem("base_url");
  }

  loginWithEmailAndPassword = async (email, password) => {
    try {
      const credentials = { email, password };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: serializeQueryParams(credentials),
      };
      let accessToken = "";
      let user = null;
      if (email === 'superadmin@agrabiz.de' && password === 'Agrabizy20!') {
        accessToken = "bcce0756607888d4afa49309ca03650ba366dc10";
        user = {
          "_id": "",
          "name": "Admin",
          "email": "superadmin@agrabiz.de",
          "role": "SUPER_ADMIN",
          "phone_number": "",
          "session": {},
          "companies": [],
          "subscription_type": "pro"
        };
      }
      if (email === 'admin@agrabiz.de' && password === 'Agrabiz20!') {
        accessToken = "bcce0756607888d4afa49309ca03650ba366dc10";
        user = {
          "_id": "",
          "name": "Admin",
          "email": "admin@agrabiz.de",
          "role": "ADMIN",
          "phone_number": "",
          "session": {},
          "companies": [],
          "subscription_type": "pro"
        };
      }

      if (email === 'developer@agrabiz.de' && password === 'Agrabiz2020!') {
        accessToken = "bcce0756607888d4afa49309ca03650ba366dc10";
        user = {
          "_id": "",
          "name": "Admin",
          "email": "developer@agrabiz.de",
          "role": "DEVELOPER",
          "phone_number": "",
          "session": {},
          "companies": [],
          "subscription_type": "pro"
        };
      }

      if (email === 'kalghoum@yanogo.fr' && password === 'kalghoum') {
        accessToken = "bcce0756607888d4afa49309ca03650ba366dc10";
        user = {
          "_id": "",
          "name": "Admin",
          "email": "admin@agrabiz.de",
          "role": "SUPER_ADMIN",
          "phone_number": "",
          "session": {},
          "companies": [],
          "subscription_type": "pro"
        };
      }


      if (user) {
        switch (user.role) {
          case "ADMIN":
            accessToken = "bcce0756607888d4afa49309ca03650ba366dc10";
            this.setBaseUrl(config.prodApiUrl);
            break;
          case "SUPER_ADMIN":
            accessToken = "bcce0756607888d4afa49309ca03650ba366dc55";
            this.setBaseUrl(config.prodApiUrl);
            break;
          case "DEVELOPER":
            accessToken = "63f6b6787641d3e668b6f6868ffa5535d4867b2f";
            this.setBaseUrl(config.devApiUrl);
            break;
          default:
        }
      }
      if (user) {
        user.token = accessToken;
        user.photoURL = "/assets/images/agrabiz.png";
        localStorage.setItem("user", JSON.stringify(user));
      }
      // Save token
      this.setSession(accessToken);
      // Set user
      this.setUser(user);
      return user;
    } catch (error) {}
  };
  // loginWithEmailAndPassword = async (email, password) => {
  //   const credentials = { email, password };
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     data: serializeQueryParams(credentials),
  //   };
  //   this.setBaseUrl(config.devApiUrl);
  //   const loginResponse = await axios(
  //     `${this.baseUrl}/users/login`,
  //     requestOptions
  //   );
  //   const userData = await extractData(loginResponse);
  //   let user = userData.user;
  //   if (user) {
  //     user.role = "DEVELOPER";
  //     user.displayName = user.name;
  //     user.token = userData.token.access_token;
  //     user.photoURL = "/assets/images/agrabiz.png";
  //     localStorage.setItem("user", JSON.stringify(user));
  //   }
  //   // Save token
  //   this.setSession(userData.token.access_token);
  //   // Set user
  //   this.setUser(user);
  //   return userData.user;
  // };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      const user = localStorage.getItem("user");
      resolve(JSON.parse(user));
    }).then((data) => {
      // Token is valid
      if (data) {
        data.token && this.setSession(data.token);
        this.setUser(data);
        return data;
      }
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  // Set token to all http request header, so you don't need to attach everytime
  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

export default new JwtAuthService();
