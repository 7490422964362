import React from "react";

const newsLetter = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const newsLetterRoutes = [
  {
    path: "/news-letter/create-news-letter",
    component: React.lazy(() => import("./NewsLetterCreate")),
    newsLetter,
  },
];

export default newsLetterRoutes;
