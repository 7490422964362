import React from "react";

const invoices = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const invoiceRoutes = [
  {
    path: "/invoice/invoices-list",
    component: React.lazy(() => import("./InvoiceList")),
    invoices,
  },
  {
    path: "/invoice/generate-invoice",
    component: React.lazy(() => import("./InvoiceGenerate")),
    invoices,
  },
];

export default invoiceRoutes;
