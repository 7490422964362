import React from "react";
import {
  Icon,
  Breadcrumbs,
  Hidden,
  Typography,
  Grid,
  Switch,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setEnvironment } from "../../app/redux/actions/EnvironmentActions";

const useStyles = makeStyles((theme) => ({
  switchLabel: {
    color: grey[500],
  },
}));

const Breadcrumb = ({ routeSegments, setEnvironment }) => {
  const classes = useStyles();
  const [checkEnv, setCheckEnv] = React.useState(true);

  const handleChange = (event) => {
    setCheckEnv(event.target.checked);
    event.target.checked
      ? setEnvironment({ type: "production" })
      : setEnvironment({ type: "development" });
  };

  return (
    <Box className="flex flex-wrap items-center" justifyContent="space-between">
      <div className="flex items-center">
        {routeSegments ? (
          <Hidden xsDown>
            <h4 className="m-0 pb-1px text-16 capitalize align-middle">
              {routeSegments[routeSegments.length - 1]["name"]}
            </h4>
            <h4 className="m-0 pb-3px ml-2 text-hint">|</h4>
          </Hidden>
        ) : null}
        <Breadcrumbs
          separator={<Icon className="text-hint">navigate_next</Icon>}
          className="flex items-center position-relative"
        >
          <NavLink to="/">
            <Icon className="align-middle ml-2 mb-1" color="primary">
              home
            </Icon>
          </NavLink>
          {routeSegments
            ? routeSegments.map((route, index) => {
                return index !== routeSegments.length - 1 ? (
                  <NavLink key={index} to={route.path}>
                    <span className="capitalize text-muted">{route.name}</span>
                  </NavLink>
                ) : (
                  <span key={index} className="capitalize text-muted">
                    {route.name}
                  </span>
                );
              })
            : null}
        </Breadcrumbs>
      </div>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item className={classes.switchLabel}>
            Development
          </Grid>
          <Grid item>
            <Switch
              checked={checkEnv}
              onChange={handleChange}
              name="checkEnv"
            />
          </Grid>
          <Grid item className={classes.switchLabel}>
            Production
          </Grid>
        </Grid>
      </Typography>
    </Box>
  );
};

export default withRouter(connect(null, { setEnvironment })(Breadcrumb));
