import React from "react";

const companies = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const companyRoutes = [
  {
    path: "/company/companies-list",
    component: React.lazy(() => import("./CompanyList")),
    companies,
  }
];

export default companyRoutes;
