import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import invoiceRoutes from "./views/invoices/InvoiceRoutes";
import newsLetterRoutes from "./views/news-letter/NewsLetterRoutes";
import companyRoutes from "./views/companies/CompanyRoutes";
import mapRoutes from "./views/map/MapRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/analytics" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...sessionRoutes,
  ...invoiceRoutes,
  ...newsLetterRoutes,
  ...companyRoutes,
  ...dashboardRoutes,
  ...mapRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
