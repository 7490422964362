import { CHANGE_ENVIRONMENT } from "../actions/EnvironmentActions";

const initialState = {};
const environmentReducer = function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_ENVIRONMENT: {
      return {
        ...state,
        ...action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default environmentReducer;
