export const CHANGE_ENVIRONMENT = "CHANGE_ENVIRONMENT";

export function setEnvironment(environment) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ENVIRONMENT,
      data: environment,
    });
  };
}
